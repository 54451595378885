import React from "react"
import MainLayout from "../components/layout/main"
import "../styles/main.scss"
import "react-input-range/lib/css/index.css"
import * as yup from "yup"

import { Formik, Field, ErrorMessage, Form } from "formik"
import TextField from "@material-ui/core/TextField"
import SEO from "../components/seo"

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}


class Contacts extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      message_sent: false,
    }
  }

  render() {
    let schema = yup.object().shape({
      email: yup.string().email("Votre e-mail est invalide.").required("E-mail ne peut pas être vide."),
      name: yup.string().required("Votre nom doit être renseigné."),
      message: yup.string().required("Merci de résumer rapidement votre projet."),
    })

    let form = <Formik
      initialValues={{ name: "", email: "", specs: [], message: "" }}
      validationSchema={schema}
      onSubmit={(values, actions) => {
        actions.setSubmitting(true)

        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": "contact",
            ...values,
          }),
        })
          .then(() => {
            this.setState({ message_sent: true })
          })
          .catch(error => {
            alert("Une erreur s'est produite, merci de réessayer ultérieurement.")
            actions.setSubmitting(false)
          })
      }}
    >
      {({
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        }) => (
        <Form>
          <div className="row">
            <div className="col-12 col-md-6 mb-spacing-32">
              <TextField
                error={errors.email && touched.email}
                label="Votre adresse e-mail"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={(errors.email && touched.email) && errors.email}
                fullWidth
              />
            </div>
            <div className="col-12 col-md-6 mb-spacing-32">
              <TextField
                error={errors.name && touched.name}
                label="Votre nom et prénom"
                name="name"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={(errors.name && touched.name) && errors.name}
                fullWidth
              />
            </div>
          </div>

          <div className="row mb-spacing-48">
            <div className="col">
              <TextField
                error={errors.message && touched.message}
                label="Description de votre projet"
                name="message"
                value={values.message}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={(errors.message && touched.message) && errors.message}
                fullWidth
                multiline
              />
            </div>
          </div>

          <div className="row">
            <div className="col">
              <button type="submit" disabled={isSubmitting} className="full_width">
                Envoyer votre message
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>

    let success = <div>
      <div style={{ textAlign: "center" }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="green">
          <path
            d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.25 8.891l-1.421-1.409-6.105 6.218-3.078-2.937-1.396 1.436 4.5 4.319 7.5-7.627z"/>
        </svg>
      </div>
      <div style={{ textAlign: "center", color: "green" }}>
        Message envoyé !<br/>
        Notre équipe vous répondra très rapidement !
      </div>
    </div>

    return <MainLayout title="Un projet ?"
                       description="Nos clients obtiennent les meilleurs résultats quand nos équipes leurs sont dédiées sur de longues périodes de temps. Décrivez brièvement votre projet, nous reviendrons vers vous dans la journée."
                       theme="light">
      <SEO title={"Contacts"}
           description={"Nos clients obtiennent les meilleurs résultats quand nos équipes leurs sont dédiées sur de longues périodes de temps. Décrivez brièvement votre projet, nous reviendrons vers vous dans la journée."}/>
      <div className="slice">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {this.state.message_sent ? success : form}
            </div>
          </div>
        </div>
      </div>

      <div className="slice">
        <div className="container">
          <div className="py-spacing-48 py-md-spacing-96">
            <h1>Un autre besoin ?</h1>
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-3 mt-spacing-24 mt-md-spacing-48">
                <h4>Prise de contact</h4>
                <a className="link" href="mailto:hello@mobiten.com">
                  hello@mobiten.com
                </a>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mt-spacing-24 mt-md-spacing-48">
                <h4>Questions</h4>
                <a className="link" href="mailto:ask@mobiten.com">
                  ask@mobiten.com
                </a>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mt-spacing-24 mt-md-spacing-48">
                <h4>Presse</h4>
                <a className="link" href="mailto:press@mobiten.com">
                  press@mobiten.com
                </a>
              </div>
              <div className="col-12 col-sm-6 col-lg-3 mt-spacing-24 mt-md-spacing-48">
                <h4>Recrutement</h4>
                <a className="link" href="mailto:hr@mobiten.com">
                  hr@mobiten.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  }
}


export default Contacts
